import React, { useEffect } from "react"
import ServicePost from "./ServicePost"
import { servicesList } from "./servicesList"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledServicePostWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spaces[64]} 0`};
`

export default function ServiceObject () {
  const { t } = useTranslation()
  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])
  return (
    <StyledServicePostWrapper>
      {servicesList.map(el => (
        <ServicePost
          key={el.id}
          imgUrl={el.imgSrc}
          imgAlt={el.title}
          title={t(el.title)}
          subtitle={t(el.subtitle)}
          text={t(el.text)}
          linkUrl={el.linkUrl}
          linkText={t(el.linkText)}
          data-aos="fade-in"
        />
      ))}
    </StyledServicePostWrapper>
  )
}
