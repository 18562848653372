import React from "react"
import styled from "styled-components"
import { ParagraphRegular, H2, H4 } from "../shared/Typography/Typography"
import { ButtonInternalLink } from "../shared/Link/Link"
import { device } from "../shared/styles/breakpoints"

const StyledMiniature = styled.img`
  width: 100%;
  object-fit: cover;
  display: block;
  height: 410px;
`

const StyledTextBox = styled.div`
  padding: ${({ theme }) => theme.spaces[32]};
  background-color: ${({ theme }) => theme.colors.white};
  margin: ${({ theme }) => `-${theme.spaces[40]} ${theme.spaces[16]} 0`};
  z-index: 1;
  position: relative;
  @media ${device.md} {
    margin: ${({ theme }) => `-${theme.spaces[40]} ${theme.spaces[100]} 0`};
  }
`

const StyledText = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  white-space: pre-wrap;
`

const StyledTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.black};
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`

const StyledSubtitle = styled(H4)`
  color: ${({ theme }) => theme.colors.black};
  line-height: ${({ theme }) => theme.lineHeights[24]};
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`

const StyledServicePost = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spaces[32]};
`

const StyledButtonInternalLink = styled(ButtonInternalLink)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spaces[8]};
`

export default function ServicePost({
  imgUrl,
  imgAlt,
  title,
  subtitle,
  text,
  linkUrl,
  linkText,
  children,
  ...props
}) {
  return (
    <StyledServicePost {...props}>
      <StyledMiniature src={imgUrl} alt={imgAlt}></StyledMiniature>
      <StyledTextBox>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        {text && <StyledText>{text}</StyledText>}
        {linkUrl && linkText && (
          <StyledButtonInternalLink to={linkUrl} sufixIcon color="primary">
            {linkText}
          </StyledButtonInternalLink>
        )}
        {children}
      </StyledTextBox>
    </StyledServicePost>
  )
}
