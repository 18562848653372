import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import YellowBox from "../components/shared/YellowBox/YellowBox"
import { graphql } from "gatsby"
import ServiceObject from "../components/ServicesPage/ServiceObject"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledService = styled.div`
  margin: 0 auto;
  max-width: 980px;
`

export default function ServicesPage () {
  const { t } = useTranslation()
  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])
  return (
    <Layout>
      <Seo title={t("Usługi")} />
      <StyledService>
        <ServiceObject />
        <YellowBox data-aos="fade-in" />
      </StyledService>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
