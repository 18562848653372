import produkcjaTVImg from "../../images/services/1_PRODUKCJA_TV.png"
import eventyImg from "../../images/services/2_EVENTY.png"
import sprzetImg from "../../images/services/3_SPRZET.png"
import esportGamingImg from "../../images/services/4_ESPORT_GAMING.png"
import produkcjaImg from "../../images/services/5_PRODUKCJA.png"
import marketingImg from "../../images/services/6_MARKETING.png"
export const servicesList = [
  {
    id: 0,
    imgSrc: produkcjaTVImg,
    title: "Produkcja TV",
    subtitle: "tvProductionTitle",
    text: "tvProductionText",
  },
  {
    id: 1,
    imgSrc: eventyImg,
    title: "Eventy",
    subtitle: "eventsTitle",
    text: "eventsText",
  },
  {
    id: 2,
    imgSrc: sprzetImg,
    title: "Sprzęt",
    subtitle: "equipmentTitle",
    linkUrl: "/equipment",
    linkText: "equipmentLinkText",
  },
  {
    id: 3,
    imgSrc: esportGamingImg,
    title: "Esport & Gaming",
    subtitle: "esportTitle",
    text: "esportText",
  },
  {
    id: 4,
    imgSrc: produkcjaImg,
    title: "Produkcja video",
    subtitle: "videoProductionTitle",
    text: "videoProductionText",
  },
  {
    id: 5,
    imgSrc: marketingImg,
    title: "Marketing",
    subtitle: "marketingTitle",
    text: "marketingText",
  },
]
